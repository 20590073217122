import Vue from 'vue';

Vue.config.productionTip = false;

const addLocationsElem = document.querySelector('#addLocations');

if (addLocationsElem) {
    new Vue({
        el: "#addLocations",
        data: {
            items: [],
            newItemContainer: null,
        },
        methods: {
            addRow() {
                let newId = 'new_' + (parseInt(this.items.length) + 1)
                this.items.push({id: newId})
                this.$nextTick(() => this.$refs['namefield' + newId][0].focus())
            },
            removeRow() {
                this.items.pop()
            },
        },
        template: '#new-location-lms-token',
    });
};
